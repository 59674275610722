import type { MenuProps } from 'antd';
import type { SubMenuType } from 'antd/es/menu/interface';

import {
  AlertOutlined,
  BellOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  DownloadOutlined,
  FlagOutlined,
  FormOutlined,
  HomeOutlined,
  LogoutOutlined,
  SettingOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from '@ant-design/icons';

import { Role } from '../../enum';
import {
  validateAsAdmin,
  validateAsFirstAlertsRole,
  validateAsServiceProviderManagerRole,
} from '../../helpers/utils.helper';
import { isMobile } from '../../store';
import type { AuthResult, CurrentUser } from '../pages/Login/authSlice';

const feedbackSubMenuChildren: MenuProps['items'] = [
  { label: 'Feedback from advocates', key: '/advocate-feedback' },
  { label: 'Feedback from guests', key: '/guest-feedback' },
  { label: 'Reported guests', key: '/abusive-guests' },
  { label: 'Reported chat logs', key: '/reports-of-abuse' },
];

const adminSubMenuChildren: MenuProps['items'] = [
  { label: 'Peer advocates', key: '/admin-advocates' },
  { label: 'Regional managers', key: '/admin-managers' },
  { label: 'Service providers', key: '/admin-service-providers' },
  { label: 'Service provider managers', key: '/admin-service-providers-managers' },
  { label: 'Advocate hours', key: '/admin-advocates-hours' },
  { label: 'Admin accounts', key: '/super-admins' },
];

const addServiceProvider: MenuProps['items'][number] = {
  label: 'Add service provider',
  icon: <UserAddOutlined />,
  key: '/service-provider-onboarding',
};

const generalItems: MenuProps['items'] = [
  {
    label: 'Program dashboard',
    icon: <HomeOutlined />,
    key: '/program-dashboard',
  },
  {
    label: 'Advocates online',
    icon: <CheckCircleOutlined />,
    key: '/advocates-online-dashboard',
  },
  {
    label: 'Downloads',
    icon: <DownloadOutlined />,
    key: '/downloads',
  },
  {
    label: 'Peer advocates',
    icon: <UsergroupAddOutlined />,
    key: '/advocates',
  },
  {
    label: 'Admin',
    icon: <UserOutlined />,
    key: 'AdminSubMenu',
  },
  { label: 'Nations / regions', icon: <FlagOutlined />, key: '/admin-communities' },
  { label: 'FA nations / regions', icon: <FlagOutlined />, key: '/first-alerts-communities' },
  {
    label: 'Feedback',
    icon: <FormOutlined />,
    key: 'FeedbackSubMenu',
  },
  { label: 'Add advocate', icon: <UserAddOutlined />, key: '/advocate-onboarding' },
  addServiceProvider,
  { label: 'Timesheets', icon: <ClockCircleOutlined />, key: '/timesheets' },
];

const firstAlertsItems: MenuProps['items'] = [
  { label: 'Control panel', icon: <BellOutlined />, key: '/alerts' },
  { label: 'Users', icon: <UserOutlined />, key: '/admin-first-alerts-managers' },
  { label: 'Nations / regions', icon: <FlagOutlined />, key: '/first-alerts-communities' },
];

const userAccountItems: MenuProps['items'] = [
  {
    label: 'My settings',
    icon: <SettingOutlined />,
    key: '/manager-settings',
  },
  { label: 'Log out', icon: <LogoutOutlined />, key: '/logout' },
];

export const getMenuItems = (currentUser: CurrentUser, authResult: AuthResult) => {
  if (validateAsAdmin(currentUser.role)) {
    const items = generalItems.concat([
      {
        label: 'FirstAlerts',
        icon: <AlertOutlined />,
        key: 'FirstAlertsSubMenu',
        children: firstAlertsItems,
      },
      ...userAccountItems,
    ]);

    return items.filter((item) => {
      switch (item.key) {
        case '/advocates':
        case '/first-alerts-communities':
          return false;
        case 'FeedbackSubMenu':
          (item as SubMenuType).children = feedbackSubMenuChildren;
          return true;
        case 'AdminSubMenu':
          (item as SubMenuType).children = adminSubMenuChildren.filter((submenu) => {
            switch (submenu.key) {
              case '/super-admins':
                return currentUser.role === Role.SUPER_ADMIN;
              default:
                return true;
            }
          });
          return true;
        default:
          return true;
      }
    });
  }

  if (validateAsFirstAlertsRole(currentUser.role)) {
    return firstAlertsItems
      .filter((item) => {
        switch (item.key) {
          case '/admin-first-alerts-managers':
            return currentUser.role === Role.ALERT_MANAGER;
          case '/first-alerts-communities':
            return false;
          default:
            return true;
        }
      })
      .concat(...userAccountItems);
  }

  if (validateAsServiceProviderManagerRole(currentUser.role)) {
    return [
      {
        label: 'Service providers',
        icon: <UsergroupAddOutlined />,
        key: '/admin-service-providers',
      },
      { label: 'Managers', icon: <UserOutlined />, key: '/admin-service-providers-managers' },
      addServiceProvider,
      ...userAccountItems,
    ];
  }

  return generalItems
    .filter((item) => {
      switch (item?.key) {
        case '/advocates':
          return currentUser.role === Role.MANAGER;
        case '/downloads':
        case '/service-provider-onboarding':
          return false;
        case 'AdminSubMenu':
          (item as SubMenuType).children = adminSubMenuChildren.filter((c) => {
            switch (c.key) {
              case '/super-admins':
              case '/admin-service-providers-managers':
              case '/admin-service-providers':
                return false;
              default:
                return true;
            }
          });
          return currentUser.role === Role.DIRECTOR && !isMobile;
        case '/first-alerts-communities':
          return authResult.use_first_alerts && currentUser.role === Role.DIRECTOR;
        case '/admin-communities':
          return authResult.use_community && currentUser.role !== Role.DASHBOARD_ONLY;
        case '/advocate-onboarding':
        case '/timesheets':
          return currentUser.role !== Role.DASHBOARD_ONLY;
        case 'FeedbackSubMenu':
          (item as SubMenuType).children = feedbackSubMenuChildren.filter((c) => {
            switch (c.key) {
              case '/reports-of-abuse':
                return currentUser.role === Role.DIRECTOR || currentUser.role === Role.MANAGER;
              default:
                return true;
            }
          });
          return currentUser.role !== Role.DASHBOARD_ONLY;
        default:
          return true;
      }
    })
    .concat(...userAccountItems);
};

import type { SorterResult, SortOrder, TablePaginationConfig } from 'antd/lib/table/interface';
import type { AxiosError } from 'axios';
import axios from 'axios';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { APIStatus } from '../../../enum';
import type {
  ChatLengthBin,
  Dow,
  GuestAlertsRegistered,
  LineChartData,
  MissedChat,
  NameValue,
  OtherSubject,
  SubjectTotal,
  TreeMapData,
} from '../../../models/dashboard';
import type { ErrorMessage } from '../../../models/error';
import type { AsyncThunkConfig } from '../../../models/slice';
import type { FilteredInfo, TableChange } from '../../../models/table';
import { RaygunErrorHandlerService } from '../../../service/raygun.service';
import { dateTimeFilterFormat, getChatDailyCSV } from './program-dashboard.helper';

const { logError } = RaygunErrorHandlerService();

class DashboardRequestParams {
  start_date: Dayjs = dayjs().subtract(1, 'month');
  end_date: Dayjs = dayjs();
}

type ModalTypes = MissedChat | OtherSubject;

export class ModalSortedInfo implements SorterResult<ModalTypes> {
  columnKey: keyof MissedChat | keyof OtherSubject | undefined = undefined;
  field: keyof MissedChat | keyof OtherSubject | undefined = undefined;
  order: SortOrder | undefined = undefined;
}

type RequestModalParam = {
  pagination: TablePaginationConfig;
  sortedInfo: ModalSortedInfo;
  filteredInfo: FilteredInfo;
};

export type DashboardData = {
  start_date: Dayjs;
  end_date: Dayjs;
  guest_conversion_rate: number;
  total_chats: number | null;
  average_chat_length: number | null;
  chat_lengths: ChatLengthBin[];
  unique_guests_chatting: number | null;
  issues: {
    reports_of_abuse: number | null;
    reports_of_emergency: number | null;
    blocked_guests: number | null;
    empty_search_results: number | null;
    missed_chats: MissedChat[];
    empty_search_sessions: number | null;
    unique_guests_with_empty_searches: number | null;
  };
  chat_activity: LineChartData[];
  search: {
    language_searches: NameValue[];
    community_searches_by_region: TreeMapData[];
    search_dow: Dow[];
  };
  feedback: {
    chat_helpful: NameValue[];
    chat_end_reasons: NameValue[];
    chat_subjects: SubjectTotal[];
    other_chat_subjects: SubjectTotal[];
    chat_referred_guest: NameValue[];
  };
  advocate_dow: Dow[];
  alerts: {
    total_alerts_sent: number | null;
    total_guest_alerts_registered: number | null;
    guest_alerts_registered: GuestAlertsRegistered | null;
  };
};

type ProgramDashboardSliceType = {
  dashboardParams: DashboardRequestParams;
  dashboardData: DashboardData;
  requestModalParam: RequestModalParam;
  chatDataCSV: string;
  dashboardApiStatus: APIStatus;
  modal: DASHBOARD_MODAL;
};

export enum DASHBOARD_MODAL {
  IDLE = 'idle',
  ADVOCATE_DAY_AND_TIME = 'advocate_day_and_time',
  SEARCH_DAY_AND_TIME = 'search_day_and_time',
  COMMUNITY_SEARCH = 'community_search',
  OTHER_SUBJECTS = 'other_subjects',
  CHAT_LENGTHS = 'chat_lengths',
  MISSED_CHATS = 'missed_chats',
  EMPTY_SEARCHES = 'empty_searches',
  ALERTS_REGISTERED = 'alerts_registered',
}

const initialState: ProgramDashboardSliceType = {
  dashboardParams: new DashboardRequestParams(),
  requestModalParam: {
    pagination: {
      current: 1,
      pageSize: 50,
      defaultPageSize: 50,
    },
    sortedInfo: new ModalSortedInfo(),
    filteredInfo: {},
  },
  chatDataCSV: '',
  dashboardData: {
    start_date: dayjs().subtract(1, 'month'),
    end_date: dayjs(),
    guest_conversion_rate: 0,
    total_chats: null,
    average_chat_length: null,
    chat_lengths: [],
    unique_guests_chatting: null,
    issues: {
      reports_of_abuse: null,
      reports_of_emergency: null,
      blocked_guests: null,
      empty_search_results: null,
      missed_chats: [],
      empty_search_sessions: null,
      unique_guests_with_empty_searches: null,
    },
    chat_activity: [],
    search: {
      language_searches: [],
      community_searches_by_region: [],
      search_dow: [],
    },
    feedback: {
      chat_helpful: [],
      chat_end_reasons: [],
      chat_subjects: [],
      other_chat_subjects: [],
      chat_referred_guest: [],
    },
    advocate_dow: [],
    alerts: {
      total_alerts_sent: null,
      total_guest_alerts_registered: null,
      guest_alerts_registered: null,
    },
  },
  modal: DASHBOARD_MODAL.IDLE,
  dashboardApiStatus: APIStatus.IDLE,
};

export const loadDashboard = createAsyncThunk<DashboardData, undefined, AsyncThunkConfig>(
  'programDashboard/loadDashboard',
  async (_, thunkAPI) => {
    try {
      const { programDashboardSlice } = thunkAPI.getState();
      const response = await axios.post('v1_program_dashboard', {
        start_date: programDashboardSlice.dashboardParams.start_date.startOf('day').format(dateTimeFilterFormat),
        end_date: programDashboardSlice.dashboardParams.end_date.endOf('day').format(dateTimeFilterFormat),
      });
      return response as unknown as DashboardData;
    } catch (e) {
      logError(e, ['programDashboardSlice', 'loadDashboard']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const programDashboardSlice = createSlice({
  name: 'programDashboard',
  initialState,
  reducers: {
    handleParamsChange: (state, action: PayloadAction<Partial<DashboardRequestParams>>) => {
      state.dashboardParams = { ...state.dashboardParams, ...action.payload };
    },
    showDashboardModal: (state, action: PayloadAction<DASHBOARD_MODAL>) => {
      state.modal = action.payload;
    },
    closeDashboardModal: (state) => {
      state.modal = DASHBOARD_MODAL.IDLE;
    },
    handleModalTableChange: (state, action: PayloadAction<Partial<TableChange<ModalTypes>>>) => {
      const pagination = action.payload.pagination;
      const filters = action.payload.filters as FilteredInfo;
      const sorter = action.payload.sorter as ModalSortedInfo;
      state.requestModalParam.pagination = pagination || initialState.requestModalParam.pagination;
      state.requestModalParam.sortedInfo = sorter || initialState.requestModalParam.sortedInfo;
      state.requestModalParam.filteredInfo = filters || initialState.requestModalParam.filteredInfo;
    },
    clearModalFilter: (state) => {
      state.requestModalParam.pagination = initialState.requestModalParam.pagination;
      state.requestModalParam.sortedInfo = initialState.requestModalParam.sortedInfo;
      state.requestModalParam.filteredInfo = initialState.requestModalParam.filteredInfo;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadDashboard.pending, (state, _action) => {
        state.dashboardApiStatus = APIStatus.PENDING;
      })
      .addCase(loadDashboard.fulfilled, (state, action) => {
        state.dashboardData = action.payload;
        state.dashboardData.start_date = state.dashboardParams.start_date;
        state.dashboardData.end_date = state.dashboardParams.end_date;
        state.chatDataCSV = getChatDailyCSV(action.payload.chat_activity);
        state.dashboardApiStatus = APIStatus.FULFILLED;
      })
      .addCase(loadDashboard.rejected, (state, _action) => {
        state.dashboardApiStatus = APIStatus.ERROR;
      });
  },
});

export const { handleParamsChange, showDashboardModal, closeDashboardModal, handleModalTableChange, clearModalFilter } =
  programDashboardSlice.actions;

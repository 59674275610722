import { notification } from 'antd';
import type { AxiosError } from 'axios';
import axios from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import type { Role } from '../../../enum';
import { ManagerPage, Org } from '../../../enum';
import { APIStatus } from '../../../enum';
import { validateAsAdmin } from '../../../helpers/utils.helper';
import type { ErrorMessage } from '../../../models/error';
import type { AsyncThunkConfig } from '../../../models/slice';
import { type FilteredInfo, type RequestParams, SortedInfo, type TableChange } from '../../../models/table';
import { RaygunErrorHandlerService } from '../../../service/raygun.service';
import { removeEmpty } from '../../../service/table.service';
import { fetchSuperAdmins } from '../super-admin/superAdminSlice';
import { validateAdminManager } from './admin-managers.helper';
import type { ResetPassword } from './adminManagerPasswordReset';

const { logError } = RaygunErrorHandlerService();

export class AdminManager {
  id: string | undefined = undefined;
  full_name: string | undefined = undefined;
  first_name: string | undefined = undefined;
  last_name: string | undefined = undefined;
  email: string | undefined = undefined;
  pass?: string | undefined = undefined;
  mobile_phone: string | undefined = undefined;
  created: string | undefined = undefined;
  role: Role | undefined = undefined;
  account_status: string | undefined = undefined;
  org_code: Org = Org.Saskatchewan;
  org_name: string | undefined = undefined;
  total_count?: number | undefined = undefined;

  // Regional manager
  regions?: string[] | null = [];
  region_numbers?: string[] | null = [];

  // FirstAlerts manager
  first_alerts_community_id?: string | undefined;
  first_alerts_community_type_id?: string | undefined;
  first_alerts_community_name?: string | undefined;

  // Service provider manager
  service_provider_organization_id?: string | undefined;
}

export enum ADMIN_MANAGER_MODAL {
  PASSWORD_RESET = 'password_reset',
  IDLE = 'idle',
  EDIT_MANAGER = 'edit_manager',
}

export type AdminOrganization = {
  code: string;
  name: string;
  internal: boolean;
  use_first_alerts: boolean;
  use_dart: boolean;
  use_service_providers: boolean;
  use_service_providers_coming_soon: boolean;
  use_community: boolean;
};

export type ServiceProviderOrganization = {
  id: string;
  name: string;
  org_code: Org;
};

type AdminManagerSlideType = {
  managers: AdminManager[];
  requestAdminManagerParam: RequestParams<AdminManager>;
  adminManagerApiStatus: APIStatus;
  adminManagerModal: ADMIN_MANAGER_MODAL;
  adminManager: AdminManager;
  adminOrganizations: AdminOrganization[];
  adminServiceProviderOrganizations: ServiceProviderOrganization[];
};

const initialState: AdminManagerSlideType = {
  managers: [],
  requestAdminManagerParam: {
    pagination: {
      current: 1,
      pageSize: 50,
      defaultPageSize: 50,
    },
    sortedInfo: new SortedInfo<AdminManager>('full_name', 'ascend'),
    filteredInfo: {},
  },
  adminManagerApiStatus: APIStatus.IDLE,
  adminManagerModal: ADMIN_MANAGER_MODAL.IDLE,
  adminManager: new AdminManager(),
  adminOrganizations: [],
  adminServiceProviderOrganizations: [],
};

const managersEndpoint = {
  [ManagerPage.RegionalManager]: 'v0_get_managers',
  [ManagerPage.FirstAlertsManager]: 'v0_get_first_alerts_managers',
  [ManagerPage.ServiceProviderManager]: 'v0_get_service_provider_managers',
};

export const fetchAdminManagers = createAsyncThunk<AdminManager[], ManagerPage, AsyncThunkConfig>(
  'adminManager/fetchAdminManagers',
  async (type, thunkAPI) => {
    try {
      const { requestAdminManagerParam } = thunkAPI.getState().adminManagerSlice;
      const response = (await axios.post(managersEndpoint[type], requestAdminManagerParam)) as AdminManager[];
      return response ?? [];
    } catch (e) {
      logError(e, ['adminManagerSlice', 'fetchAdminManagers', type]);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const fetchOrganizations = createAsyncThunk<AdminOrganization[], undefined, AsyncThunkConfig>(
  'adminManagerSlice/fetchOrganizations',
  async (_, thunkAPI) => {
    try {
      const response = (await axios.post('v0_get_manager_organizations')) as AdminOrganization[];
      return response ?? [];
    } catch (e) {
      logError(e, ['adminManagerSlice', 'fetchOrganizations']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const fetchServiceProviderOrganizations = createAsyncThunk<
  ServiceProviderOrganization[],
  undefined,
  AsyncThunkConfig
>('adminManagerSlice/fetchServiceProviderOrganizations', async (_, thunkAPI) => {
  try {
    const response = (await axios.post(
      'v0_get_service_provider_manager_organizations',
    )) as ServiceProviderOrganization[];
    return response ?? [];
  } catch (e) {
    logError(e, ['adminManagerSlice', 'fetchServiceProviderOrganizations']);
    return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
  }
});

export const adminManagerPasswordReset = createAsyncThunk<string, ResetPassword, AsyncThunkConfig>(
  'adminManager/adminManagerPasswordReset',
  async (resetPassword, thunkAPI) => {
    try {
      const response = (await axios.post('v0_set_manager_password', resetPassword)) as string; // id;
      if (response) {
        notification.success({
          message: 'Success',
          description: 'Password set successfully.',
        });
      }
      return response;
    } catch (e) {
      logError(e, ['adminManagerSlice', 'adminManagerPasswordReset']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export type SetAdminManagerStatusData = { manager_id: string; status: string; type: ManagerPage };

export const setAdminManagerStatus = createAsyncThunk<string, SetAdminManagerStatusData, AsyncThunkConfig>(
  'adminManager/setAdminManagerStatus',
  async ({ type, ...data }, thunkAPI) => {
    try {
      const response = (await axios.post('v0_manager_status_set', data)) as string; // id;
      if (response) {
        notification.success({
          message: 'Success',
          description: `Your manager has been successfully ${data.status === 'active' ? 'unarchived' : 'archived'}.`,
        });
        const { currentUser } = thunkAPI.getState().authSlice;
        if (validateAsAdmin(currentUser.role)) {
          thunkAPI.dispatch(fetchSuperAdmins());
        }
        thunkAPI.dispatch(fetchAdminManagers(type));
      }
      return response;
    } catch (e) {
      logError(e, ['adminManagerSlice', 'setAdminManagerStatus']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

class AdminManagerUpsertResponse {
  manager_id: string = '';
  is_update: boolean = false;
}

export const upsertAdminManager = createAsyncThunk<
  AdminManagerUpsertResponse,
  { upsertAdminManager: AdminManager; type: ManagerPage },
  AsyncThunkConfig
>('adminManager/upsertAdminManager', async ({ upsertAdminManager, type }, thunkAPI) => {
  try {
    const currentUserRole = thunkAPI.getState().authSlice.currentUser.role;
    if (validateAdminManager(upsertAdminManager, currentUserRole)) {
      const response = (await axios.post('v0_manager_upsert', upsertAdminManager)) as AdminManagerUpsertResponse;
      if (response) {
        thunkAPI.dispatch(fetchAdminManagers(type));
        thunkAPI.dispatch(closeAdminManagerModal());
        notification.success({
          message: `Manager ${response.is_update ? 'updated' : 'added'} successfully`,
          description: 'Success',
        });
        return response;
      }
    }
    return new AdminManagerUpsertResponse();
  } catch (e) {
    logError(e, ['adminManagerSlice', 'upsertAdminManager']);
    return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
  }
});

export const adminManagerSlice = createSlice({
  name: 'adminManager',
  initialState,
  reducers: {
    handleManagerTableChange: (state, action: PayloadAction<TableChange<AdminManager>>) => {
      const filters = action.payload.filters as FilteredInfo;
      const sorter = action.payload.sorter as SortedInfo<AdminManager>;
      if (!sorter.order) {
        sorter.order = null;
      }
      state.requestAdminManagerParam.pagination = action.payload.pagination;
      state.requestAdminManagerParam.sortedInfo = sorter;
      state.requestAdminManagerParam.filteredInfo = removeEmpty(filters);
    },
    clearFilter: (state) => {
      state.requestAdminManagerParam.pagination = initialState.requestAdminManagerParam.pagination;
      state.requestAdminManagerParam.sortedInfo = initialState.requestAdminManagerParam.sortedInfo;
      state.requestAdminManagerParam.filteredInfo = initialState.requestAdminManagerParam.filteredInfo;
    },
    showEditModal: (state, action: PayloadAction<ADMIN_MANAGER_MODAL>) => {
      state.adminManagerModal = action.payload;
    },
    setAdminManager: (state, action: PayloadAction<AdminManager>) => {
      state.adminManager = action.payload;
    },
    closeAdminManagerModal: (state) => {
      state.adminManagerModal = ADMIN_MANAGER_MODAL.IDLE;
      state.adminManager = new AdminManager();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminManagers.pending, (state, _action) => {
        state.adminManagerApiStatus = APIStatus.PENDING;
      })
      .addCase(fetchAdminManagers.fulfilled, (state, action) => {
        state.managers = action.payload;
        state.adminManagerApiStatus = APIStatus.FULFILLED;
      })
      .addCase(fetchAdminManagers.rejected, (state, _action) => {
        state.adminManagerApiStatus = APIStatus.ERROR;
      })
      .addCase(adminManagerPasswordReset.fulfilled, (state) => {
        state.adminManagerModal = ADMIN_MANAGER_MODAL.IDLE;
      })
      .addCase(fetchOrganizations.fulfilled, (state, action) => {
        state.adminOrganizations = action.payload;
      })
      .addCase(fetchServiceProviderOrganizations.fulfilled, (state, action) => {
        state.adminServiceProviderOrganizations = action.payload;
      });
  },
});

export const { handleManagerTableChange, clearFilter, showEditModal, setAdminManager, closeAdminManagerModal } =
  adminManagerSlice.actions;

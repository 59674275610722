import { notification } from 'antd';
import type { AxiosError } from 'axios';
import axios from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { AdvocatePage, APIStatus } from '../../../enum';
import type { ErrorMessage } from '../../../models/error';
import type { AsyncThunkConfig } from '../../../models/slice';
import type { FilteredInfo, RequestParams, TableChange } from '../../../models/table';
import { SortedInfo } from '../../../models/table';
import { RaygunErrorHandlerService } from '../../../service/raygun.service';
import { removeEmpty } from '../../../service/table.service';

const { logError } = RaygunErrorHandlerService();

export type AdminAdvocate = {
  org_name?: string;
  nickname: string;
  account_status: string;
  community_names: string[];
  community_ids: string[];
  created: string;
  email: string;
  first_name: string;
  full_name: string;
  id: string;
  internal: boolean;
  languages: string[];
  manager_names: string[];
  last_name: string;
  total_count: number;
  archived: string;
  mobile_phone: string;

  // LEPS
  dart?: boolean;
  org_use_dart?: boolean;
  label_ids?: string[];
  labels?: string[];
  advocate_type?: string;

  // Service provider
  service_provider_organization_id?: string;
  service_provider_title?: string;
  service_title?: string;
  service_description?: string;
  service_provider_organization_name?: string;
};

type AdminAdvocateSliceType = {
  adminAdvocates: AdminAdvocate[];
  requestAdminAdvocatesParam: RequestParams<AdminAdvocate>;
  adminAdvocatesApiStatus: APIStatus;
};

const initialState: AdminAdvocateSliceType = {
  adminAdvocates: [],
  requestAdminAdvocatesParam: {
    pagination: {
      current: 1,
      pageSize: 50,
      defaultPageSize: 50,
    },
    sortedInfo: new SortedInfo<AdminAdvocate>('full_name', 'ascend'),
    filteredInfo: {},
  },
  adminAdvocatesApiStatus: APIStatus.IDLE,
};

export const fetchAdminAdvocates = createAsyncThunk<AdminAdvocate[], AdvocatePage, AsyncThunkConfig>(
  'adminAdvocate/fetchAdminAdvocates',
  async (type, thunkAPI) => {
    try {
      const url = type === AdvocatePage.PeerAdvocate ? 'v0_get_leps' : 'v0_get_service_providers';
      const { requestAdminAdvocatesParam } = thunkAPI.getState().adminAdvocateSlice;
      const response = await axios.post(url, requestAdminAdvocatesParam);
      return response as unknown as AdminAdvocate[];
    } catch (e) {
      logError(e, ['adminAdvocateSlice', 'fetchAdminAdvocates', type]);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

type SetInternalData = {
  advocate_id: string;
  internal: boolean;
  type: AdvocatePage;
};

export const adminAdvocateSetInternal = createAsyncThunk<string, SetInternalData, AsyncThunkConfig>(
  'adminAdvocate/setInternal',
  async ({ type, ...setInternalData }, thunkAPI) => {
    try {
      const response = await axios.post('v0_advocate_internal_set', setInternalData);
      if (response) {
        await thunkAPI.dispatch(fetchAdminAdvocates(type));
      }
      return response as unknown as string; // id
    } catch (e) {
      logError(e, ['adminAdvocateSlice', 'adminAdvocateSetInternal', type]);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export type SetDartData = {
  advocate_id: string;
  dart: boolean;
};

export const adminAdvocateSetDart = createAsyncThunk<string, SetDartData, AsyncThunkConfig>(
  'adminAdvocate/setDart',
  async (setDartData, thunkAPI) => {
    try {
      const response = await axios.post('v0_advocate_dart_set', setDartData);
      if (response) {
        await thunkAPI.dispatch(fetchAdminAdvocates(AdvocatePage.PeerAdvocate));
      }
      return response as unknown as string; // id
    } catch (e) {
      logError(e, ['adminAdvocateSlice', 'adminAdvocateSetDart']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export type SetAdminAdvocateStatusData = { advocate_id: string; status: string; type: AdvocatePage };

export const setAdminAdvocateStatus = createAsyncThunk<string, SetAdminAdvocateStatusData, AsyncThunkConfig>(
  'adminAdvocate/setAdminManagerStatus',
  async ({ type, ...data }, thunkAPI) => {
    try {
      const url = `v0_set_${type === AdvocatePage.PeerAdvocate ? 'advocate' : 'service_provider'}_status`;
      const response = (await axios.post(url, data)) as string; // id;
      if (response) {
        notification.success({
          message: 'Success',
          description: `Your ${
            type === AdvocatePage.PeerAdvocate ? 'advocate' : 'service provider'
          } has been successfully ${data.status === 'active' ? 'unarchived' : 'archived'}.`,
        });
        thunkAPI.dispatch(fetchAdminAdvocates(type));
      }
      return response;
    } catch (e) {
      logError(e, ['adminAdvocateSlice', 'setAdminAdvocateStatus']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const adminAdvocateSlice = createSlice({
  name: 'adminAdvocate',
  initialState,
  reducers: {
    handleAdvocateTableChange: (state, action: PayloadAction<TableChange<AdminAdvocate>>) => {
      const filters = action.payload.filters as FilteredInfo;
      const sorter = action.payload.sorter as SortedInfo<AdminAdvocate>;
      if (!sorter.order) {
        sorter.order = null;
      }
      state.requestAdminAdvocatesParam.pagination = action.payload.pagination;
      state.requestAdminAdvocatesParam.sortedInfo = sorter;
      state.requestAdminAdvocatesParam.filteredInfo = removeEmpty(filters);
    },
    clearFilter: (state) => {
      state.requestAdminAdvocatesParam.pagination = initialState.requestAdminAdvocatesParam.pagination;
      state.requestAdminAdvocatesParam.sortedInfo = initialState.requestAdminAdvocatesParam.sortedInfo;
      state.requestAdminAdvocatesParam.filteredInfo = initialState.requestAdminAdvocatesParam.filteredInfo;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminAdvocates.pending, (state, _action) => {
        state.adminAdvocatesApiStatus = APIStatus.PENDING;
      })
      .addCase(fetchAdminAdvocates.fulfilled, (state, action) => {
        state.adminAdvocates = action.payload;
        state.adminAdvocatesApiStatus = APIStatus.FULFILLED;
      })
      .addCase(fetchAdminAdvocates.rejected, (state, _action) => {
        state.adminAdvocatesApiStatus = APIStatus.ERROR;
      });
  },
});

export const { handleAdvocateTableChange, clearFilter } = adminAdvocateSlice.actions;
